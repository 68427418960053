<template>
  <input
    :value="inputValue"
    v-bind="attrs"
    type="radio"
    @input="handleInput"
  >
  <slot name="label">
    <label
      v-if="label"
      :for="attrs.id || ''"
      class="ml-2"
    >
      {{ label }}
    </label>
  </slot>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
export default {
    name: 'BaseRadio',

    inheritAttrs: false,

    props: {
        modelValue: {
            type: [String, Number, Boolean],
            default: false,
            description: 'bound value'
        },
        label: {
            type: String,
            default: '',
            description: 'label for radio input'
        }
    },

    emits: [
        'update:modelValue'
    ],

    setup (props, { attrs, emit }) {
        // data binding logic
        const inputValue = ref(false);
        onMounted(() => {
            inputValue.value = props.modelValue;
        });
        watch(
            () => props.modelValue,
            () => {
                inputValue.value = props.modelValue;
            }
        );
        const handleInput = (event) => {
            inputValue.value = event.target.value;
            emit('update:modelValue', inputValue.value);
        };

        return {
            inputValue,
            handleInput,
            attrs
        };
    }
};
</script>
